import React from 'react';
import CustomChart from "../components/CustomChart"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Home() {
    return (
        <div className="section w-100">
            <Container fluid>
                <Row className="mb-4">
                    <Col className="text-center">
                        <h1 className="display-4">Probability Distribution Explorer</h1>
                    </Col>
                </Row>
                <Row className="mb-5 pb-5">
                    <Col lg={2} className="d-none d-lg-block"></Col>
                    <Col md={12} lg={8}>
                        <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                            <CustomChart />
                        </div>
                    </Col>
                    <Col lg={2} className="d-none d-lg-block"></Col>
                </Row>
                <Row>
                    <Col md={12} lg={8} className="mx-auto mt-5 pt-5">
                        <h2>Explore Probability Distributions</h2>
                        <p>Welcome to our comprehensive probability distribution visualization tool. Whether you're a student, researcher, or data science enthusiast, this interactive platform allows you to explore and understand various probability distributions with ease.</p>
                        <p>Select from a wide range of probability distributions, including binomial, Poisson, normal, and more. Adjust parameters in real-time to see how they affect the shape and characteristics of each distribution.</p>
                        <h3>Key Features:</h3>
                        <ul>
                            <li>Interactive visualizations of popular probability distributions</li>
                            <li>Real-time parameter adjustments</li>
                            <li>Educational insights into each distribution's properties and applications</li>
                            <li>User-friendly interface suitable for beginners and experts alike</li>
                        </ul>
                        <p>Enhance your understanding of probability theory and statistical concepts through hands-on experimentation with our dynamic distribution graphs.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Home;