import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../components/Navbar";

const Layout = () => {
  return (
    <div className="container-fluid h-100">
        <div className="row h-100">
          <Navbar />
        
            <main role="main" className="ml-sm-auto col-lg-10 px-4">
                <Outlet />
            </main>
        </div>
    </div>
  );
};

export default Layout;