import React, { useState } from "react";
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-black d-lg-none">
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleSidebar}
          aria-controls="sidebar"
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>

      <nav className={`col-lg-2 bg-black sidebar ${isOpen ? 'd-block' : 'd-none d-lg-block'}`}>
        <div className="sidebar-sticky">
          <ul class="nav flex-column">
              <li class="nav-item">
                <Link to="/" className="nav-link">Probability Distribution Explorer</Link>
              </li>
              <li class="nav-item">
                <Link to="/about" className="nav-link">About</Link>
              </li>
              <li class="nav-item">
                <Accordion defaultActiveKey="" className="accordion-flush">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Discrete Distributions</Accordion.Header>
                    <Accordion.Body className="bg-black">
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Bernoulli" className="nav-link">Bernoulli</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Binomial" className="nav-link">Binomial</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Geometric" className="nav-link">Geometric</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Poisson" className="nav-link">Poisson</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Hypergeometric" className="nav-link">Hypergeometric</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/Uniform" className="nav-link">Uniform</Link>
                        </li>
                        <li class="nav-item">
                          <Link to="/discrete-distributions/NegativeBinomial" className="nav-link">Negative Binomial</Link>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;