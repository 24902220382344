import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BinomialPage() {
    return (
        <>
        <Helmet>
           <title>Binomial Distribution | Interactive Probability Explorer</title>
           <meta name="description" content="Explore the Binomial distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
        </Helmet>
        <div className="section w-100">
            <Container fluid>
                <Row className="mb-4">
                    <Col className="text-center">
                        <h1 className="display-4">Binomial Distribution</h1>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col lg={2} className="d-none d-lg-block"></Col>
                    <Col md={12} lg={8}>
                        <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                            <DoubleDistribution selectedDist={"binomial"}/>
                        </div>
                    </Col>
                    <Col lg={2} className="d-none d-lg-block"></Col>
                </Row>
                {/* Responsive spacer for small screens */}
                <Row className="d-md-none">
                    <Col>
                        <div style={{ height: '60vh', minHeight: '400px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={8} className="mx-auto">
                        <h2>What is the Binomial Distribution?</h2>
                        <p>The Binomial distribution is a discrete probability distribution that models the number of successes in a fixed number of independent Bernoulli trials. It's widely used in statistics and probability theory to analyze binary outcome experiments.</p>

                        <h2>Examples of Binomial Distributions</h2>
                        <p>Binomial distributions are applicable in various scenarios:</p>
                        <ul>
                            <li>Number of heads in multiple coin flips</li>
                            <li>Number of defective items in a batch of products</li>
                            <li>Number of successful sales calls in a day</li>
                            <li>Number of patients responding to a treatment in a clinical trial</li>
                        </ul>

                        <h2>What do the parameters n and p mean?</h2>
                        <p>The Binomial distribution has two parameters:</p>
                        <ul>
                            <li><strong>n</strong>: The number of trials (a positive integer)</li>
                            <li><strong>p</strong>: The probability of success on each trial (a value between 0 and 1)</li>
                        </ul>

                        <h2>Support</h2>
                        <p>The support of a Binomial distribution is the set of integers &#123;0, 1, 2, ..., n&#125;.</p>

                        <h2>Probability Mass Function (PMF)</h2>
                        <p>The PMF of a Binomial distribution is given by:</p>
                        <p>P(X = k) = C(n,k) * p<sup>k</sup> * (1-p)<sup>(n-k)</sup></p>
                        <p>Where C(n,k) is the binomial coefficient, also known as "n choose k".</p>

                        <h2>Cumulative Distribution Function (CDF)</h2>
                        <p>The CDF of a Binomial distribution is the probability that the number of successes is less than or equal to k:</p>
                        <p>F(k) = Σ<sub>i=0</sub><sup>k</sup> C(n,i) * p<sup>i</sup> * (1-p)<sup>(n-i)</sup></p>

                        <h2>Moments</h2>
                        <p>Key moments of the Binomial distribution:</p>
                        <ul>
                            <li>Mean (Expected value): E[X] = np</li>
                            <li>Variance: Var(X) = np(1-p)</li>
                            <li>Skewness: (1-2p) / √(np(1-p))</li>
                            <li>Kurtosis: 3 + (1-6p(1-p)) / (np(1-p))</li>
                        </ul>

                        <h2>Related Distributions</h2>
                        <p>The Binomial distribution is related to several other distributions:</p>
                        <ul>
                            <li>Bernoulli distribution: A Binomial distribution with n=1</li>
                            <li>Poisson distribution: The limit of a Binomial distribution as n approaches infinity and p approaches 0</li>
                            <li>Normal distribution: Approximates the Binomial distribution for large n</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
}

export default BinomialPage;