import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function GeometricPage() {
    return (
        <>
        <Helmet>
           <title>Geometric Distribution | Interactive Probability Explorer</title>
           <meta name="description" content="Explore the Geometric distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
         </Helmet>
        <div className="section w-100">
            <Container fluid>
                <Row className="mb-4">
                    <Col className="text-center">
                        <h1 className="display-4">Geometric Distribution</h1>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col lg={2} className="d-none d-lg-block"></Col>
                    <Col md={12} lg={8}>
                        <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                            <DoubleDistribution selectedDist={"geometric"}/>
                        </div>
                    </Col>
                    <Col lg={2} className="d-none d-lg-block"></Col>
                </Row>
                {/* Responsive spacer for small screens */}
                <Row className="d-md-none">
                    <Col>
                        <div style={{ height: '60vh', minHeight: '400px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={8} className="mx-auto">
                        <h2>What is the Geometric Distribution?</h2>
                        <p>The Geometric distribution is a discrete probability distribution that models the number of failures before the first success in a sequence of independent Bernoulli trials. It's an important distribution in probability theory and has applications in various fields.</p>

                        <h2>Examples of Geometric Distributions</h2>
                        <p>Geometric distributions can model various real-world scenarios:</p>
                        <ul>
                            <li>Number of coin flips until the first heads appears</li>
                            <li>Number of attempts before succeeding at a task</li>
                            <li>Number of items inspected before finding a defective one</li>
                            <li>Number of days until a rare event occurs</li>
                        </ul>

                        <h2>What does the parameter p mean?</h2>
                        <p>The Geometric distribution has one parameter:</p>
                        <ul>
                            <li><strong>p</strong>: The probability of success on each trial (a value between 0 and 1)</li>
                        </ul>

                        <h2>Support</h2>
                        <p>The support of a Geometric distribution is the set of non-negative integers &#123;0, 1, 2, ...&#125; or &#123;1, 2, 3, ...&#125;, depending on whether you're counting the number of failures before the first success (0-based) or the number of trials until the first success (1-based).</p>

                        <h2>Probability Mass Function (PMF)</h2>
                        <p>The PMF of a Geometric distribution (0-based) is given by:</p>
                        <p>P(X = k) = (1-p)<sup>k</sup> * p</p>
                        <p>For the 1-based version, it's:</p>
                        <p>P(X = k) = (1-p)<sup>k-1</sup> * p</p>

                        <h2>Cumulative Distribution Function (CDF)</h2>
                        <p>The CDF of a Geometric distribution (0-based) is:</p>
                        <p>F(k) = 1 - (1-p)<sup>k+1</sup></p>
                        <p>For the 1-based version, it's:</p>
                        <p>F(k) = 1 - (1-p)<sup>k</sup></p>

                        <h2>Moments</h2>
                        <p>Key moments of the Geometric distribution (0-based):</p>
                        <ul>
                            <li>Mean (Expected value): E[X] = (1-p) / p</li>
                            <li>Variance: Var(X) = (1-p) / p<sup>2</sup></li>
                            <li>Skewness: (2-p) / √(1-p)</li>
                            <li>Kurtosis: 6 + (p<sup>2</sup> / (1-p))</li>
                        </ul>

                        <h2>Related Distributions</h2>
                        <p>The Geometric distribution is related to several other distributions:</p>
                        <ul>
                            <li>Negative Binomial distribution: The Geometric distribution is a special case of the Negative Binomial distribution</li>
                            <li>Exponential distribution: The continuous analog of the Geometric distribution</li>
                            <li>Bernoulli distribution: The Geometric distribution models the number of Bernoulli trials until the first success</li>
                        </ul>
                    </Col>
                </Row>
                </Container>
        </div>
        </>
    );
}

export default GeometricPage;