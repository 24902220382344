import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HypergeometricPage() {
    return (
        <>
            <Helmet>
                <title>Hypergeometric Distribution | Interactive Probability Explorer</title>
                <meta name="description" content="Explore the Hypergeometric distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
            </Helmet>
            <div className="section w-100">
                <Container fluid>
                    <Row className="mb-4">
                        <Col className="text-center">
                            <h1 className="display-4">Hypergeometric Distribution</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={2} className="d-none d-lg-block"></Col>
                        <Col md={12} lg={8}>
                            <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                                <DoubleDistribution selectedDist={"hypergeometric"}/>
                            </div>
                        </Col>
                        <Col lg={2} className="d-none d-lg-block"></Col>
                    </Row>
                    {/* Responsive spacer for small screens */}
                    <Row className="d-md-none">
                        <Col>
                            <div style={{ height: '60vh', minHeight: '400px' }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={8} className="mx-auto">
                            <h2>What is the Hypergeometric Distribution?</h2>
                            <p>The Hypergeometric distribution is a discrete probability distribution that describes the probability of k successes in n draws, without replacement, from a finite population of size N that contains exactly K successes.</p>

                            <h2>Examples of Hypergeometric Distributions</h2>
                            <p>Hypergeometric distributions can be applied in various scenarios:</p>
                            <ul>
                                <li>Drawing cards from a deck without replacement</li>
                                <li>Quality control sampling from a batch of products</li>
                                <li>Ecological studies sampling animal populations</li>
                                <li>Auditing financial records</li>
                            </ul>

                            <h2>What do the parameters N, K, and n mean?</h2>
                            <p>The Hypergeometric distribution has three parameters:</p>
                            <ul>
                                <li><strong>N</strong>: The total number of items in the population</li>
                                <li><strong>K</strong>: The number of items with the desired characteristic in the population</li>
                                <li><strong>n</strong>: The number of items drawn from the population</li>
                            </ul>

                            <h2>Support</h2>
                            <p>The support of a Hypergeometric distribution is the set of integers &#123;max(0, n+K-N), ..., min(n, K)&#125;.</p>

                            <h2>Probability Mass Function (PMF)</h2>
                            <p>The PMF of a Hypergeometric distribution is given by:</p>
                            <p>P(X = k) = [C(K,k) * C(N-K,n-k)] / C(N,n)</p>
                            <p>Where C(n,k) is the binomial coefficient.</p>

                            <h2>Cumulative Distribution Function (CDF)</h2>
                            <p>The CDF of a Hypergeometric distribution is:</p>
                            <p>F(x) = Σ<sub>k=0</sub><sup>x</sup> [C(K,k) * C(N-K,n-k)] / C(N,n)</p>

                            <h2>Moments</h2>
                            <p>Key moments of the Hypergeometric distribution:</p>
                            <ul>
                                <li>Mean (Expected value): E[X] = n * (K/N)</li>
                                <li>Variance: Var(X) = n * (K/N) * ((N-K)/N) * ((N-n)/(N-1))</li>
                                <li>Skewness: ((N-2K)(N-1)<sup>1/2</sup>(N-2n)) / (((n(N-n)K(N-K))<sup>1/2</sup>(N-2))</li>
                                <li>Kurtosis: Complex expression (depends on all parameters)</li>
                            </ul>

                            <h2>Related Distributions</h2>
                            <p>The Hypergeometric distribution is related to several other distributions:</p>
                            <ul>
                                <li>Binomial distribution: As N approaches infinity, the Hypergeometric distribution approaches the Binomial distribution</li>
                                <li>Negative Hypergeometric distribution: Models the number of failures before a specified number of successes in sampling without replacement</li>
                                <li>Fisher's noncentral hypergeometric distribution: A generalization where the probability of success changes between groups</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default HypergeometricPage;