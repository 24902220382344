import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PoissonPage() {
    return (
        <>
            <Helmet>
                <title>Poisson Distribution | Interactive Probability Explorer</title>
                <meta name="description" content="Explore the Poisson distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
            </Helmet>
            <div className="section w-100">
                <Container fluid>
                    <Row className="mb-4">
                        <Col className="text-center">
                            <h1 className="display-4">Poisson Distribution</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={2} className="d-none d-lg-block"></Col>
                        <Col md={12} lg={8}>
                            <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                                <DoubleDistribution selectedDist={"poisson"}/>
                            </div>
                        </Col>
                        <Col lg={2} className="d-none d-lg-block"></Col>
                    </Row>
                    {/* Responsive spacer for small screens */}
                    <Row className="d-md-none">
                        <Col>
                            <div style={{ height: '60vh', minHeight: '400px' }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={8} className="mx-auto">
                            <h2>What is the Poisson Distribution?</h2>
                            <p>The Poisson distribution is a discrete probability distribution that expresses the probability of a given number of events occurring in a fixed interval of time or space, assuming these events occur with a known constant mean rate and independently of the time since the last event.</p>

                            <h2>Examples of Poisson Distributions</h2>
                            <p>Poisson distributions are applicable in various scenarios:</p>
                            <ul>
                                <li>Number of calls received by a call center per hour</li>
                                <li>Number of defects in a certain length of fabric</li>
                                <li>Number of mutations in a given stretch of DNA</li>
                                <li>Number of cars arriving at a drive-through window in a given time period</li>
                            </ul>

                            <h2>What does the parameter λ (lambda) mean?</h2>
                            <p>The Poisson distribution has one parameter:</p>
                            <ul>
                                <li><strong>λ (lambda)</strong>: The average number of events in the interval (must be positive)</li>
                            </ul>

                            <h2>Support</h2>
                            <p>The support of a Poisson distribution is the set of non-negative integers &#123;0, 1, 2, ...&#125;.</p>

                            <h2>Probability Mass Function (PMF)</h2>
                            <p>The PMF of a Poisson distribution is given by:</p>
                            <p>P(X = k) = (e<sup>-λ</sup> * λ<sup>k</sup>) / k!</p>
                            <p>Where e is Euler's number and k! is the factorial of k.</p>

                            <h2>Cumulative Distribution Function (CDF)</h2>
                            <p>The CDF of a Poisson distribution is:</p>
                            <p>F(k) = e<sup>-λ</sup> * Σ<sub>i=0</sub><sup>k</sup> (λ<sup>i</sup> / i!)</p>

                            <h2>Moments</h2>
                            <p>Key moments of the Poisson distribution:</p>
                            <ul>
                                <li>Mean (Expected value): E[X] = λ</li>
                                <li>Variance: Var(X) = λ</li>
                                <li>Skewness: 1 / √λ</li>
                                <li>Kurtosis: 1 / λ</li>
                            </ul>

                            <h2>Related Distributions</h2>
                            <p>The Poisson distribution is related to several other distributions:</p>
                            <ul>
                                <li>Binomial distribution: The Poisson distribution is the limit of the binomial distribution as the number of trials goes to infinity while the product of trials and probability remains fixed</li>
                                <li>Exponential distribution: The time between events in a Poisson process follows an exponential distribution</li>
                                <li>Gamma distribution: The waiting time until the k-th event in a Poisson process follows a gamma distribution</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default PoissonPage;