import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function UniformPage() {
    return (
        <>
            <Helmet>
                <title>Discrete Uniform Distribution | Interactive Probability Explorer</title>
                <meta name="description" content="Explore the Discrete Uniform distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
            </Helmet>
            <div className="section w-100">
                <Container fluid>
                    <Row className="mb-4">
                        <Col className="text-center">
                            <h1 className="display-4">Discrete Uniform Distribution</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={2} className="d-none d-lg-block"></Col>
                        <Col md={12} lg={8}>
                            <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                                <DoubleDistribution selectedDist={"uniform"}/>
                            </div>
                        </Col>
                        <Col lg={2} className="d-none d-lg-block"></Col>
                    </Row>
                    {/* Responsive spacer for small screens */}
                    <Row className="d-md-none">
                        <Col>
                            <div style={{ height: '60vh', minHeight: '400px' }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={8} className="mx-auto">
                            <h2>What is the Discrete Uniform Distribution?</h2>
                            <p>The Discrete Uniform distribution is a probability distribution that assigns equal probability to a finite set of discrete outcomes. Each possible value in the set has an equal chance of occurring.</p>

                            <h2>Examples of Discrete Uniform Distributions</h2>
                            <p>Discrete Uniform distributions can be applied in various scenarios:</p>
                            <ul>
                                <li>Rolling a fair die (outcomes 1 to 6)</li>
                                <li>Randomly selecting a card from a well-shuffled deck</li>
                                <li>Choosing a random integer from a specified range</li>
                                <li>Modeling equal probability events in simulations</li>
                            </ul>

                            <h2>What do the parameters a and b mean?</h2>
                            <p>The Discrete Uniform distribution has two parameters:</p>
                            <ul>
                                <li><strong>a</strong>: The lower bound of the range (inclusive)</li>
                                <li><strong>b</strong>: The upper bound of the range (inclusive)</li>
                            </ul>

                            <h2>Support</h2>
                            <p>The support of a Discrete Uniform distribution is the set of integers &#123;a, a+1, a+2, ..., b&#125;.</p>

                            <h2>Probability Mass Function (PMF)</h2>
                            <p>The PMF of a Discrete Uniform distribution is given by:</p>
                            <p>P(X = k) = 1 / (b - a + 1) for k ∈ &#123;a, a+1, ..., b&#125;</p>

                            <h2>Cumulative Distribution Function (CDF)</h2>
                            <p>The CDF of a Discrete Uniform distribution is:</p>
                            <p>F(x) = 0 for x &lt; a</p>
                            <p>F(x) = (⌊x⌋ - a + 1) / (b - a + 1) for a ≤ x &lt; b</p>
                            <p>F(x) = 1 for x ≥ b</p>
                            <p>Where ⌊x⌋ denotes the floor function (largest integer not greater than x).</p>

                            <h2>Moments</h2>
                            <p>Key moments of the Discrete Uniform distribution:</p>
                            <ul>
                                <li>Mean (Expected value): E[X] = (a + b) / 2</li>
                                <li>Variance: Var(X) = ((b - a + 1)<sup>2</sup> - 1) / 12</li>
                                <li>Skewness: 0 (symmetric distribution)</li>
                                <li>Kurtosis: -6/5 * (b - a + 1)<sup>2</sup> / ((b - a - 1)(b - a + 3))</li>
                            </ul>

                            <h2>Related Distributions</h2>
                            <p>The Discrete Uniform distribution is related to several other distributions:</p>
                            <ul>
                                <li>Continuous Uniform distribution: The continuous analog of the Discrete Uniform distribution</li>
                                <li>Categorical distribution: A generalization where probabilities may not be equal</li>
                                <li>Binomial distribution: Approaches a Discrete Uniform distribution when p = 0.5 and n is large</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default UniformPage;