import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NegativeBinomialPage() {
    return (
        <>
            <Helmet>
                <title>Negative Binomial Distribution | Interactive Probability Explorer</title>
                <meta name="description" content="Explore the Negative Binomial distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
            </Helmet>
            <div className="section w-100">
                <Container fluid>
                    <Row className="mb-4">
                        <Col className="text-center">
                            <h1 className="display-4">Negative Binomial Distribution</h1>
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        <Col lg={2} className="d-none d-lg-block"></Col>
                        <Col md={12} lg={8}>
                            <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                                <DoubleDistribution selectedDist={"negativeBinomial"}/>
                            </div>
                        </Col>
                        <Col lg={2} className="d-none d-lg-block"></Col>
                    </Row>
                    {/* Responsive spacer for small screens */}
                    <Row className="d-md-none">
                        <Col>
                            <div style={{ height: '60vh', minHeight: '400px' }}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={8} className="mx-auto">
                            <h2>What is the Negative Binomial Distribution?</h2>
                            <p>The Negative Binomial distribution is a discrete probability distribution that models the number of successes in a sequence of independent and identically distributed Bernoulli trials before a specified number of failures occur.</p>

                            <h2>Examples of Negative Binomial Distributions</h2>
                            <p>Negative Binomial distributions can be applied in various scenarios:</p>
                            <ul>
                                <li>Number of sales calls before achieving a set number of successful sales</li>
                                <li>Number of fish caught before catching a specified number of a particular species</li>
                                <li>Number of insurance claims filed before a certain number of fraudulent claims are detected</li>
                                <li>Number of trials needed to achieve a specified number of successes in a series of experiments</li>
                            </ul>

                            <h2>What do the parameters r and p mean?</h2>
                            <p>The Negative Binomial distribution has two parameters:</p>
                            <ul>
                                <li><strong>r</strong>: The number of failures until the experiment is stopped (must be a positive integer)</li>
                                <li><strong>p</strong>: The probability of success on each trial (a value between 0 and 1)</li>
                            </ul>

                            <h2>Support</h2>
                            <p>The support of a Negative Binomial distribution is the set of non-negative integers &#123;0, 1, 2, ...&#125;.</p>

                            <h2>Probability Mass Function (PMF)</h2>
                            <p>The PMF of a Negative Binomial distribution is given by:</p>
                            <p>P(X = k) = C(k+r-1, k) * p<sup>k</sup> * (1-p)<sup>r</sup></p>
                            <p>Where C(n,k) is the binomial coefficient.</p>

                            <h2>Cumulative Distribution Function (CDF)</h2>
                            <p>The CDF of a Negative Binomial distribution is:</p>
                            <p>F(k) = 1 - I<sub>p</sub>(k+1, r)</p>
                            <p>Where I<sub>p</sub>(a, b) is the regularized incomplete beta function.</p>

                            <h2>Moments</h2>
                            <p>Key moments of the Negative Binomial distribution:</p>
                            <ul>
                                <li>Mean (Expected value): E[X] = (r * p) / (1 - p)</li>
                                <li>Variance: Var(X) = (r * p) / (1 - p)<sup>2</sup></li>
                                <li>Skewness: (1 + p) / √(r * p)</li>
                                <li>Kurtosis: 6/r + (1 - p)<sup>2</sup> / (r * p)</li>
                            </ul>

                            <h2>Related Distributions</h2>
                            <p>The Negative Binomial distribution is related to several other distributions:</p>
                            <ul>
                                <li>Geometric distribution: A special case of the Negative Binomial distribution where r = 1</li>
                                <li>Poisson distribution: The limit of the Negative Binomial distribution as r approaches infinity and p approaches 0</li>
                                <li>Gamma distribution: The continuous analog of the Negative Binomial distribution</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default NegativeBinomialPage;