import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function About() {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>About Our Probability Distribution Explorer</h1>
          <p>Welcome to the About page of our Interactive Probability Distribution Explorer. We're dedicated to making probability theory and statistical concepts accessible to everyone.</p>
          
          <h2>Our Mission</h2>
          <p>Our mission is to demystify probability distributions and provide a powerful, user-friendly tool for students, educators, researchers, and data science enthusiasts. By visualizing these complex mathematical concepts, we aim to enhance understanding and intuition in the field of probability and statistics.</p>
          
          <h2>What We Offer</h2>
          <ul>
            <li>Interactive visualizations of various probability distributions</li>
            <li>Real-time parameter adjustments to explore distribution properties</li>
            <li>Educational content explaining the significance and applications of each distribution</li>
            <li>A platform suitable for both beginners and advanced users</li>
          </ul>
          
          <h2>Why Probability Distributions Matter</h2>
          <p>Probability distributions are fundamental to statistics, data science, and many fields of scientific research. They help us model uncertainty, make predictions, and understand variability in data. Our tool brings these abstract concepts to life, allowing users to gain insights through hands-on exploration.</p>
          
          <h2>Who We Are</h2>
          <p>We are a team of statisticians, data scientists, and educators passionate about making complex mathematical concepts more approachable. Our diverse backgrounds in academia and industry allow us to create a tool that's both rigorous and practical.</p>
          
          <p>Start exploring probability distributions today and enhance your understanding of this crucial field in mathematics and data science!</p>
        </Col>
      </Row>
    </Container>
  );
}

export default About;