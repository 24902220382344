import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import BernoulliPage from "./pages/Bernoulli";
import BinomialPage from "./pages/Binomial";
import GeometricPage from "./pages/Geometric";
import PoissonPage from "./pages/Poisson";
import HypergeometricPage from "./pages/Hypergeometric";
import UniformPage from "./pages/Uniform";
import NegativeBinomialPage from "./pages/NegativeBinomial";


function App() {
  return (
    <BrowserRouter>
      <Routes id="Routes">
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="discrete-distributions/Bernoulli" element={<BernoulliPage />} />
          <Route path="discrete-distributions/Binomial" element={<BinomialPage />} />
          <Route path="discrete-distributions/Geometric" element={<GeometricPage />} />
          <Route path="discrete-distributions/Poisson" element={<PoissonPage />} />
          <Route path="discrete-distributions/Hypergeometric" element={<HypergeometricPage />} />
          <Route path="discrete-distributions/Uniform" element={<UniformPage />} />
          <Route path="discrete-distributions/NegativeBinomial" element={<NegativeBinomialPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;