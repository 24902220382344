// App.js
import React, { useState, useEffect, useRef } from "react";
import { distributions } from "../utils/distributions"
import { Link } from "react-router-dom"
import Distribution from './DiscreteDistribution';

const CustomChart = () => {
  const [selectedDist, setSelectedDist] = useState("binomial");
  const initialParams = distributions[selectedDist].params.reduce(
    (acc, param) => ({ ...acc, [param.name]: param.default }),
    {}
  );
  const [params, setParams] = useState(initialParams)

  useEffect(() => {
    const defaultParams = distributions[selectedDist].params.reduce(
      (acc, param) => ({ ...acc, [param.name]: param.default }),
      {}
    );
    setParams(defaultParams);
  }, [selectedDist]);

  const handleDistChange = (event) => {
    setSelectedDist(event.target.value);
  };

  const handleParamChange = (event) => {
    setParams({ ...params, [event.target.name]: parseFloat(event.target.value) });
  };

  const selectedDistribution = distributions[selectedDist];
  
  return (
    <div className="h-100">
      <Distribution key={selectedDist} distribution={selectedDistribution} params={params} />

      <div className="container">
        <div className="row py-1">
          <div className="col-4">
            <label className="form-label h5" htmlFor="distribution-select">Distribution: </label>
          </div>
          <div className="col-8">
            <select className="form-select" id="distribution-select" value={selectedDist} onChange={handleDistChange}>
              {Object.entries(distributions).map(([key, dist]) => (
                <option key={key} value={key}>
                  {dist.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedDistribution.params.map((param) => (
          <div key={param.name} className="row py-2">
            <div className="col-4">
              <label className="form-label h5" htmlFor={`${param.name}-input`}>{param.label}: {params[param.name]}
              </label>
            </div>
            <div className="col-8">
              <input
                key={`${selectedDist}-${param.name}`}
                id={`${param.name}-input`}
                type="range"
                className="form-range"
                name={param.name}
                min={param.min}
                max={param.max}
                step={param.step}
                value={params[param.name]}
                onChange={handleParamChange}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomChart;