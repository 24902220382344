import React from 'react';
import { Helmet } from 'react-helmet';
import { DoubleDistribution } from '../components/DiscreteDistribution';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function BernoulliPage() {
    return (
        <>
        <Helmet>
           <title>Bernoulli Distribution | Interactive Probability Explorer</title>
           <meta name="description" content="Explore the Bernoulli distribution interactively. Learn about its properties, examples, PMF, CDF, moments, and related distributions in probability theory." />
        </Helmet>
        <div className="section w-100">
            <Container fluid>
                <Row className="mb-4">
                    <Col className="text-center">
                        <h1 className="display-4">Bernoulli Distribution</h1>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col lg={2} className="d-none d-lg-block"></Col>
                    <Col md={12} lg={8}>
                        <div className="chart-container" style={{ height: '60vh', minHeight: '400px' }}>
                            <DoubleDistribution selectedDist={"bernoulli"}/>
                        </div>
                    </Col>
                    <Col lg={2} className="d-none d-lg-block"></Col>
                </Row>
                {/* Responsive spacer for small screens */}
                <Row className="d-md-none">
                    <Col>
                        <div style={{ height: '60vh', minHeight: '400px' }}></div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={8} className="mx-auto">
                        <h2>What is the Bernoulli Distribution?</h2>
                        <p>The Bernoulli distribution is a discrete probability distribution for a random variable that takes on only two possible values, typically denoted as success (1) or failure (0). It's named after Swiss mathematician Jacob Bernoulli and is fundamental in probability theory and statistics.</p>

                        <h2>Examples of Bernoulli Distributions</h2>
                        <p>Bernoulli distributions model binary outcomes in various scenarios:</p>
                        <ul>
                            <li>Flipping a coin (heads or tails)</li>
                            <li>A product passing or failing quality control</li>
                            <li>Winning or losing a game</li>
                            <li>A customer making a purchase or not</li>
                        </ul>

                        <h2>What Does the Parameter p Mean?</h2>
                        <p>The parameter p in a Bernoulli distribution represents the probability of success (getting a 1). It's a value between 0 and 1, where:</p>
                        <ul>
                            <li>p = 0 means the event will never occur</li>
                            <li>p = 1 means the event will always occur</li>
                            <li>0 &lt; p &lt; 1 represents the probability of success for each trial</li>
                        </ul>

                        <h2>Support</h2>
                        <p>The support of a Bernoulli distribution is the set &#123;0, 1&#125;.</p>

                        <h2>Probability Mass Function (PMF)</h2>
                        <p>The PMF of a Bernoulli distribution is given by:</p>
                        <p>P(X = k) = p<sup>k</sup> * (1-p)<sup>(1-k)</sup> for k ∈ &#123;0, 1&#125;</p>

                        <h2>Cumulative Distribution Function (CDF)</h2>
                        <p>The CDF of a Bernoulli distribution is:</p>
                        <ul>
                            <li>F(x) = 0 for x &lt; 0</li>
                            <li>F(x) = 1 - p for 0 ≤ x &lt; 1</li>
                            <li>F(x) = 1 for x ≥ 1</li>
                        </ul>

                        <h2>Moments</h2>
                        <p>Key moments of the Bernoulli distribution:</p>
                        <ul>
                            <li>Mean (Expected value): E[X] = p</li>
                            <li>Variance: Var(X) = p(1-p)</li>
                            <li>Skewness: (1-2p) / √(p(1-p))</li>
                            <li>Kurtosis: (1-6p(1-p)) / (p(1-p))</li>
                        </ul>

                        <h2>Related Distributions</h2>
                        <p>The Bernoulli distribution is closely related to several other distributions:</p>
                        <ul>
                            <li>Binomial distribution: Sum of n independent Bernoulli trials</li>
                            <li>Geometric distribution: Number of Bernoulli trials until the first success</li>
                            <li>Negative binomial distribution: Number of successes in a sequence of Bernoulli trials before a specified number of failures occur</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
}

export default BernoulliPage;